import React from 'react';

class HomePageComponent extends React.Component {

   render() {
        return (
            <div>
                
                

            </div>
        )
    }
}

export default HomePageComponent;