import React from 'react';

class HeaderComponent extends React.Component {
    render() {
        return (
            <div><h1>Welcome to Piyush Sharma's Site</h1></div>
        )
    }
}

export default HeaderComponent;